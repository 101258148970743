import {
  eMobileMenu,
  eMenu,
  debounce,
  screenCategory
}
from './library';

import axios from 'axios';

// ================== POLYFILL IE10 FOREACH ===================== //
(function () {
  if ( typeof NodeList.prototype.forEach === 'function' ) return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();

import Glide from '@glidejs/glide';

//Global Project Variables and elements
const MAIN_MENU = document.querySelector('#main-navigation .menu'); //main menu
const TOP_MENU = document.querySelector('#top-navigation .menu'); //main menu
const Burger_Button = document.getElementById('burger-icon'); // burger icon

//This object is used store settings for mobile menu
const MOBILE_MENU_SETTINGS = {
  element: Burger_Button,
  menu: MAIN_MENU,
  direction: 'right', //options are left and right
};
//The variance in when the function on the scroll listener will fire
const SCROLL_DEBOUNCE = 5;
//The variance in when the function on the resize listener will fire
const RESIZE_DEBOUNCE = 5;

//Create mobile menu
eMobileMenu(MOBILE_MENU_SETTINGS);

//create main navigation menu
eMenu(MAIN_MENU);
eMenu(TOP_MENU);

// //on scroll event listener comment out if you don't need it.
// window.addEventListener('scroll', debounce(() => {
//   //example of calling animation function
//   //animate(element,null, 100);
//   }
// , SCROLL_DEBOUNCE));

window.addEventListener('resize', debounce(() => {
  "use strict";
  //check if window is at 1000 pixels or larger
  if(screenCategory() === 'desktop') {
    //check if mobile menu is still open
    if(MAIN_MENU.classList.contains('open')) {
      //check the direction of the animation
      let close = MOBILE_MENU_SETTINGS.direction === 'left' ? 'navFadeOutLeft' : 'navFadeOutRight';
      //create click event to close mobile menu
      MOBILE_MENU_SETTINGS.element.click();
      //remove the closing animation
      MOBILE_MENU_SETTINGS.menu.classList.remove(close);
    }
  }
},RESIZE_DEBOUNCE));

// -----------
// SITE SEARCH

const searchToggle = document.querySelector('.search-toggle');
const searchForm = document.querySelector('.nav-search-form');

searchToggle.addEventListener('click', () => {
  searchForm.classList.toggle('show');
});

document.addEventListener('click', function(event) {
  const isClickInsideSearch = searchToggle.contains(event.target) || searchForm.contains(event.target);
  if (!isClickInsideSearch) {
    if(searchForm.classList.contains('show')){
      searchForm.classList.remove('show');
    }
  }
});

// ----------------
// HOME PAGE SELECT

const homeHelp = document.querySelector('.how-to-help');
const homeHelpSelect = document.querySelector('.help-select');

if(homeHelp){
  homeHelpSelect.addEventListener('click', () =>{
    homeHelp.classList.toggle('open');
  });

  document.addEventListener('click', function(event) {
    const isClickInsideSelect = homeHelp.contains(event.target);
    if (!isClickInsideSelect) {
      if(homeHelp.classList.contains('open')){
        homeHelp.classList.remove('open');
      }
    }
  });
}

// -------
// DROPCAP

const firstText = document.querySelector('.lf-text p:first-child');
if(firstText){
  firstText.classList.add('dropcap');
}

// ---------
// ACCORDION

const accordionItems = document.querySelectorAll('.lf-accordion-item');

for (const accordion of accordionItems){
  const accordionTitle = accordion.querySelector('.accordion-label');
  const accordionText = accordion.querySelector('.accordion-text');

  // Hide accordion text
  accordionText.style.display = 'none';

  // Display on toggle
  accordionTitle.addEventListener('click', function(){
    accordion.classList.toggle('open');
  });
}

// ---------
// GALLERIES

const largeImage = document.querySelector('.large-image img');
const thumbnails = document.querySelectorAll('.glide__slide a');

if(largeImage){

  for(const thumbnail of thumbnails){
    thumbnail.addEventListener('click', function(){
      const src = thumbnail.dataset.large;
      largeImage.src = src;
    });
  }
  new Glide('.thumbnails', {
    type: 'slider',
    startAt: 0,
    perView: 5,
    peek: 10,
    breakpoints: {

      680: {
        perView: 3
      }
    }
  }).mount();
}

// ---------------
// HOME HERO IMAGE

let randomImage = '';
const heroCover = document.querySelector('.home-hero');
const heroCoverBG = document.querySelector('.home-hero-bg');

if(heroCover){

  const imageCount = heroCover.getAttribute('data-hero-images');
  randomImage = Math.floor(Math.random() * imageCount) + 1;

  heroCover.classList.add(`home-cover-${randomImage}`);

  setTimeout(function(){
    heroCoverBG.classList.add('hide');
  }, 200);
}

// -----------------
// KEY SECTORS IMAGE

const currentSector = window.location.pathname.split("/").slice(-1)[0];
let allStories = [];
let randomStory = [];

const successStory = document.querySelector('.success-stories-feature');
const successStoryTitle = document.querySelector('.feature-title');
const successStoryDescription = document.querySelector('.feature-description');
const successStoryLink = document.querySelector('.feature-link');

if(successStory){
  axios
    .get(`/!/Fetch/collection/success_story?taxonomy=sectors/${currentSector}`)
    .then(response => {
      const stories = response.data.data;

      stories.forEach(function(story){
        allStories.push(story);
      });

      selectRandomStory(allStories);
    });
}

function selectRandomStory(){
  randomStory = allStories[Math.floor(Math.random()*allStories.length)];

  successStory.style.backgroundImage = `url('${ randomStory.cover_image }')`;
  successStoryTitle.textContent = randomStory.title;
  successStoryDescription.textContent = randomStory.preview_text;
  successStoryLink.href = randomStory.url;
}
