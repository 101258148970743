// ---------------------
// --- STAFF + BOARD ---
// ---------------------

const readMores = document.querySelectorAll('.read-more-toggle');

if(readMores){
  for(const readMore of readMores){
    readMore.addEventListener('click', function(){

      const parent = readMore.parentElement;

      parent.classList.toggle('read-more');
      const readText = readMore.querySelector('.read-text');

      if(parent.classList.contains('read-more')){
        readText.textContent = 'Read Less';
        readMore.classList.add('toggled');
      } else {
        readText.textContent = 'Read More';
        readMore.classList.remove('toggled');
      }

    });
  }
}

// -----------------------
// --- DOCUMENT CENTER ---
// -----------------------

const documentWrap = document.querySelector('.document-center');
const documents = document.querySelectorAll('.document');
const docSorter = document.querySelector('#document-sorter');

if(documentWrap){
  docSorter.addEventListener('change', docSort);
  
  Array.from(documents).sort((a, b) =>
  b.dataset.date.toLowerCase().localeCompare(a.dataset.date.toLowerCase())
).forEach(el => el.parentNode.appendChild(el));
}

function docSort(){
  const sortValue = docSorter.value;

  if(sortValue == 'az'){
    Array.from(documents).sort((a, b) =>
      a.dataset.title.toLowerCase().localeCompare(b.dataset.title.toLowerCase())
    ).forEach(el => el.parentNode.appendChild(el));
  } else if(sortValue == 'za'){
    Array.from(documents).sort((a, b) =>
      b.dataset.title.toLowerCase().localeCompare(a.dataset.title.toLowerCase())
    ).forEach(el => el.parentNode.appendChild(el));
  } else if (sortValue == 'newest'){

    Array.from(documents).sort((a, b) =>
      b.dataset.date.toLowerCase().localeCompare(a.dataset.date.toLowerCase())
    ).forEach(el => el.parentNode.appendChild(el));
  } else if (sortValue == 'oldest'){
    Array.from(documents).sort((a, b) =>
      a.dataset.date.toLowerCase().localeCompare(b.dataset.date.toLowerCase())
    ).forEach(el => el.parentNode.appendChild(el));
  }
}

// ------------------
// --- INCENTIVES ---
// ------------------

const incentiveFilter = document.querySelectorAll('.incentives-filter a');

for(const filter of incentiveFilter){
  filter.addEventListener('click', function(){
    const siblings = filter.parentNode.children;
    for(const sibling of siblings){
      sibling.classList.remove('active');
    }
    filter.classList.add('active');

    const incentiveLevel = filter.dataset.level;
    incentiveSort(incentiveLevel);
  });
}

function incentiveSort(level){
  const incentiveCards = document.querySelectorAll('.incentive-card');

  incentiveCards.forEach(function(card){
    const cardData = card.dataset.type;

    card.classList.add('hidden');

    if(level == 'all'){
      card.classList.remove('hidden');
    } else if(level == cardData) {
      card.classList.remove('hidden');
    } else {

    }
  });

}

// ----------------
// --- PROPERTY ---
// ----------------

// const filters = document.querySelectorAll('.filter');

// for(const filter of filters){
//   const toggle = filter.querySelector('.filter-toggle');
//   const filterText = filter.querySelector('.filter-text');

//   toggle.addEventListener('click', function(){
//     filterText.classList.toggle('show');
//   });
// }
